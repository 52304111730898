import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import HowToRegIcon from '@mui/icons-material/HowToReg';

const FreeGuide = () => {
    return (
        <Box
            py={'3rem'}
            textAlign={'center'}
            alignItems={'center'}
            sx={{
                backgroundImage:
                    'radial-gradient(at center center, #475B70 0%, #253342 100%)',
            }}
        >
            <Grid
                container
                spacing={5}
                display={'flex'}
                justifyContent={'center'}
            >
                {/* <Grid item xs={12} md={2}>
                </Grid> */}
                <Grid item xs={12} md={4} color={'white'}>
                    <HowToRegIcon sx={{ fontSize: '7rem', color: '#c5a774' }} />
                    <Typography variant="h4" textTransform={'uppercase'}>
                        Free Guide
                    </Typography>
                    <Typography variant="h5">
                        Discover The 3 Reasons Men Get Stuck In Life And How To
                        Fix It.
                    </Typography>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() =>
                            window.open(
                                'https://coa.renewedmind.coach/hvg/3-reasons',
                                '_blank',
                                'noreferrer'
                            )
                        }
                        sx={{
                            backgroundColor: '#c5a774',
                            color: 'white',
                            mt: 2,
                            textTransform: 'uppercase',
                            fontWeight: 700,
                        }}
                    >
                        Download The Guide
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default FreeGuide;
