import React from 'react';
import { useTheme, ThemeProvider } from '@mui/material';
import TopIntro from './LangingPage/TopIntro';
import CommonObstacles from './LangingPage/CommonObstacles';
import HereToHelp from './LangingPage/HereToHelp';
import HowDoesItWork from './LangingPage/HowDoesItWork';
import Outcome from './LangingPage/Outcome';
import FreeGuide from './FreeGuide';

const LandingPage = () => {
    const theme = useTheme();
    theme.typography.h2 = {
        fontSize: '3rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem',
        },
    };

    return (
        <ThemeProvider theme={theme}>
            <TopIntro />
            <CommonObstacles />
            <HereToHelp />
            <HowDoesItWork />
            <Outcome />
            <FreeGuide />
        </ThemeProvider>
    );
};

export default LandingPage;
