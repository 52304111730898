import React from 'react';
import { Box, Typography, Grid, useTheme, useMediaQuery } from '@mui/material';

const Outcome = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box pt={'4rem'} textAlign={'center'} alignItems={'center'}>
            <Grid container spacing={5} py={'3rem'}>
                <Grid item xs={12} md={6} py={2} textAlign={'center'} mx={3}>
                    <Typography
                        variant="h4"
                        fontFamily={"'Raleway',sans-serif;"}
                    >
                        Unlock your potential
                    </Typography>
                    <Typography variant="h6" textTransform={'uppercase'}>
                        START BUILDING THE LIFE YOU WANT
                    </Typography>
                    <Typography
                        variant="body1"
                        py={5}
                        px={2}
                        textAlign={'left'}
                        maxWidth={'1024px'}
                    >
                        Imagine how life will feel when you have a clear vision
                        of what you want, an action plan to get it, and the
                        mindset to move forward with confidence. Imagine how it
                        will feel to have a guide by your side every step of the
                        way, no longer feeling alone.
                        <br />
                        <br />
                        If you don't do something different, you will continue
                        to be stuck where you are. You will continue wasting
                        time and feeling lost and alone.
                        <br />
                        <br />
                        No more wasting time. Stop letting fear and limiting
                        beliefs control you. Unlock your full potential!
                        <br />
                        <br />
                        Don't stay stuck, book a free Get Unstuck Session today
                        and begin building the life you want.
                        <br />
                        <br />
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={4}
                    textAlign={isMobile ? 'center' : 'right'}
                >
                    <img
                        src="\images\success.jpg"
                        alt="Success"
                        style={{
                            maxWidth: '100%',
                            height: 'auto',
                            padding: 0,
                            margin: 0,
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Outcome;
