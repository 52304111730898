import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { HandshakeOutlined } from '@mui/icons-material';
import ScheduleButton from '../ScheduleButton';

const OfferedServices = () => {
    return (
        <Box
            py={'3rem'}
            textAlign={'center'}
            sx={{
                backgroundColor: '#f2ece0',
                backgroundImage: 'url(/images/bgdots.png)',
            }}
        >
            <Grid
                container
                spacing={4}
                py={'3rem'}
                px={2}
                display={'flex'}
                justifyContent={'center'}
            >
                <Grid item xs={12} md={4} textAlign={'center'}>
                    <HandshakeOutlined
                        sx={{ fontSize: '7rem', color: '#c5a774' }}
                    />
                    <Typography variant="h6">One-On-One Coaching</Typography>
                    <Typography
                        variant="body1"
                        py={5}
                        px={2}
                        textAlign={'left'}
                        maxWidth={'1024px'}
                    >
                        In my 3-month Renewed Man Coaching Program, I will walk
                        you through the process of creating a VISION, making an
                        ACTION PLAN, and building a positive MINDSET so that you
                        can turn your vision into reality in a series of weekly
                        1-hour sessions.
                        <br />
                        <br />
                        <Typography variant="h6" textAlign={'center'}>
                            Vision
                        </Typography>
                        <Typography variant="body1" textAlign={'center'}>
                            Create a VISION so you know what you want without
                            feeling lost
                        </Typography>
                        <br />
                        <Typography variant="h6" textAlign={'center'}>
                            Action Plan
                        </Typography>
                        <Typography variant="body1" textAlign={'center'}>
                            Make an ACTION PLAN so you know how to get it
                            without feeling overwhelmed
                        </Typography>
                        <br />
                        <Typography variant="h6" textAlign={'center'}>
                            Mindset
                        </Typography>
                        <Typography variant="body1" textAlign={'center'}>
                            Build a positive MINDSET so you can move forward
                            without fear or limiting beliefs
                        </Typography>
                        <br />
                        <Typography variant="h6" textAlign={'center'}>
                            Support
                        </Typography>
                        <Typography variant="body1" textAlign={'center'}>
                            I'll walk you through each step and keep you moving
                            forward
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
            <ScheduleButton />
        </Box>
    );
};
export default OfferedServices;
