import React from 'react';
import { Button, SxProps, Theme } from '@mui/material';
import { EventCategory, sendEvent } from '../utils/Analytics';

interface ScheduleButtonProps {
    className?: string | undefined;
    sx?: SxProps<Theme> | undefined;
}

const defaultSx = {
    backgroundImage:
        'radial-gradient(at center center, #475B70 0%, #253342 100%)',
};

const ScheduleButton = ({ sx, className }: ScheduleButtonProps) => {
    const finalStyles = sx ? { ...defaultSx, ...sx } : defaultSx;

    return (
        <span className="App">
            <Button
                variant="contained"
                color="success"
                onClick={() => {
                    sendEvent(
                        EventCategory.BookACallBtn,
                        'click',
                        'Book a call button clicked'
                    );
                    window.open('https://coa.renewedmind.coach/hvg/book-now');
                }}
                sx={finalStyles}
                className={className}
            >
                Book a free session
            </Button>
        </span>
    );
};

export default ScheduleButton;
